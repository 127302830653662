import { useGetAPI, usePostAPI } from '@api/useAPI';
import {
	AddressSearchResponse,
	PostcodeParseResponse,
	ParseAddressResponse,
	ServerFlag,
	AppEnums,
	TDocument,
	TIssueAndClaimDocument
} from '@types';
import axios from 'axios';
import { useState } from 'react';

/*
 *  Dummy Fetch for XSRF token
 */

export const useGetXSRF = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/login',
		domain: 'Inertia'
	});

	return {
		isLoading,
		xsrf: data,
		error,
		callGetXSRF: callAPI
	}
}

/*
 *  ENUM Fetch
 */

export const useGetEnums = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/api/enum',
		domain: 'AdminAPI'
	});

	return {
		isLoading,
		enums: data as { data: AppEnums[] },
		error,
		callGetEnums: callAPI
	}
}

export const useGetEOSEnums = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/v2/super-dock/enums', });

	return {
		isEosEnumLoading: isLoading,
		eosEnums: data,
		eosEnumError: error,
		callGetEOSEnums: callAPI
	}
}

/*
 *	Feature Flag Fetch
 *	NOTE: The URL here is only for testing...
 *	we may need to update this in the future...
 */

export const useGetFeatureFlags = (key:string, secret:string) => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: `/api/flags?key=${key}&secret=${secret}`,
		domain: 'AdminAPI'
	});

	return {
		isLoading,
		featureFlags: data as ServerFlag[],
		ffError: error,
		callGetFeatureFlags: callAPI
	}
}

/*
 *  GeoJSON Calls
 */

export const useGetRegionPolygonAndExtent = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/region-polygon?region={1}' });

	return {
		isLoading,
		regionPolygonsAndExtents: data,
		regionPolygonExtentError: error,
		callGetRegionPolygonAndExtent: callAPI
	}
}

/**
 * Fetch current user(me) data
 */
export const useGetMe = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: `/api/me` });

	return {
		isLoadingMe: isLoading,
		me: data,
		meError: error,
		callGetMe: callAPI
	}
}

/*
 *  Fetch User/Team Data
 */

export const useGetTeam = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: `/api/team` });

	return {
		isLoadingTeam: isLoading,
		team: data,
		teamError: error,
		callGetTeam: callAPI
	}
}


export const useGetTeamABMList = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: `/api/admin/team/abm-list` });

	return {
		isLoadingTeam: isLoading,
		team: data,
		teamError: error,
		callGetTeam: callAPI
	}
}


/*
 *	EOS API - We need to filter address/region/postcode on eos database
 */
export const useGetAutocomplete = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/autocomplete?term={1}&locality_type={2}' });

	return {
		loadingAutocomplete: isLoading,
		addresses: data as AddressSearchResponse,
		getAutocompleteError: error,
		callGetAutocomplete: callAPI
	}
}

export const useGetParseAddress = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/address-api/address/parse?address={1}' });

	return {
		loadingParseAddress: isLoading,
		parsedAddress: data as ParseAddressResponse,
		getParseAddressError: error,
		callGetParseAddress: callAPI
	}
}

export const useGetParsePostcode = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/address-api/postcode/parse?postcode={1}' });

	return {
		loadingParsePostcode: isLoading,
		parsedPostcode: data as PostcodeParseResponse,
		getParsePostcodeError: error,
		callGetParsePostcode: callAPI
	}
}

export const useGetParseRegion = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ endpoint: '/api/address-api/region/brief/by-name/{1}' });

	return {
		loadingParseRegion: isLoading,
		parsedRegion: data as PostcodeParseResponse,
		getParseRegionError: error,
		callGetParseRegion: callAPI
	}
}

export const useGetDocument = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({ 
		endpoint: '/documents/{1}?format={2}',
		domain: 'Inertia'
	});
	
	const triggerAPI = (id: number, format: string) => {
		callAPI({ routes: [`${id}`, `${format}`] });
	}
	return {
		loadingGetDocument: isLoading,
		document: data as string,
		getDocumentError: error,
		callGetDocument: triggerAPI
	}
}

export const useGetMultipleDocuments = () => {
	const {
		isLoading,
		error,
	} = useGetAPI({ 
		endpoint: '/documents/{1}?format={2}',
		domain: 'Inertia'
	});
	const [retData, setRetData] = useState<any | null>(null);

	const downloadDocument = async (id: number, format: string) => {
		return await axios.get(`/inertia/documents/${id}?format=${format}`)
		.then((res) => {
			return res.data as string
		});
    }

	const triggerAPI = async (documents: TIssueAndClaimDocument[], format: string) => {
		const documentPromises = documents?.map(async (document) => {
			const documentUri = await downloadDocument(document.doc_id, format);
			return {
				uri: documentUri,
				fileType: document.document.object_type,
			};
		}) ?? [];

		const docsToSend = await Promise.all(documentPromises);
		setRetData(docsToSend);

	}
	const getData = () => {
		if (!retData) return null;
		return retData as any;
	}
	return {
		loadingGetDocuments: isLoading,
		documents: getData(),
		getDocumentsError: error,
		callGetDocuments: triggerAPI
	}
}

export const usePostDocument = () => {
	const {
		isLoading,
		data,
		error,
		callAPI
	} = usePostAPI({ 
		endpoint: '/documents',
		headers: {
			"Content-Type": "multipart/form-data",
		},
		domain: 'Inertia'
	});
	
	const triggerAPI = (payload: FormData) => { 
		callAPI({payload});
	}

	return {
		loadingPostDocument: isLoading,
		postDocumentData: data as {data: TDocument},
		getPostDocumentError: error,
		callPostDocument: triggerAPI
	}
}