import React, {
	useEffect,
	useRef,
	useState,
	PropsWithChildren,
	Children,
	cloneElement,
	KeyboardEvent,
	FocusEvent,
	ElementType,
	ReactElement,
	createContext,
	Dispatch,
	SetStateAction
} from 'react';
import { createPortal } from 'react-dom';
import styles from './Select.module.scss';
import { Option } from './Option';
import { OptGroup } from './Group';
import { SELECT_ICON_POS, VALIDATION_ERROR, TObject } from '@types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Caption, Spacer } from '@components';
import { ZodTypeAny } from 'zod';
import { v4 as uuid } from 'uuid';

type TProps = {
	value: any | any[];
	onChange: (e:any, g?: string[]) => void;
	name: string;
	group?: string[] | string;
	Icon?: ElementType;
	iconPos?: SELECT_ICON_POS;
	label?: string;
	disabled?: boolean;
	required?: boolean;
	multiple?: boolean;
	id?: string;
	'data-testid'?: string;
	displayThin?: boolean;
	onMenuClose?: () => void;
	validations?: ZodTypeAny;
	hasError?: boolean;
	errorMessage?: string | JSX.Element;
	onValidationError?: (error:VALIDATION_ERROR, clear:boolean) => void;
	onValidationSuccess?: (success:VALIDATION_ERROR, clear: boolean) => void;
	keepErrorSpace?: boolean;
	tabIndex?: number;
	placeholder?: string;
	onBlur?: (e:FocusEvent<HTMLButtonElement>) => void;
	width?: string;
	borderless?: boolean;
	styleValueAs?: 'p' | 'h1';
	listHeightOffset?: number;
	hasNested?: boolean;
	hasMultiLevel?: boolean;
	hasAccordian?: boolean;
	showGroupWithVal?: boolean;
	groupValSeparator?: string;
}

export type TGroupObj = {
	id: string;
	name: string;
}

export type TContext = {
	hasNested?: boolean;
	hasMultiLevel?: boolean;
	hasAccordian?: boolean;
	prevLevel?: TGroupObj[];
	setContextState?: Dispatch<SetStateAction<TContext | null>>;
}

export const SelectContext = createContext<TContext | null>(null);

const SelectContainer = (props:PropsWithChildren<TProps>) => {
	const {
		value,
		onChange,
		name,
		group,
		Icon,
		iconPos,
		label,
		disabled,
		required,
		multiple,
		id,
		'data-testid': dataTestId,
		displayThin,
		onMenuClose,
		validations,
		hasError,
		errorMessage,
		onValidationError,
		onValidationSuccess,
		keepErrorSpace,
		tabIndex,
		placeholder,
		onBlur,
		width,
		borderless,
		styleValueAs,
		listHeightOffset,
		hasNested,
		hasMultiLevel,
		hasAccordian,
		showGroupWithVal,
		groupValSeparator = ': ',
		children
	} = props;

	const [contextState, setContextState] = useState<TContext | null>(null);
	const [comboOpen, setComboOpen] = useState(false);
	const [showList, setShowList] = useState(false);
	const [isAboveFold, setIsAboveFold] = useState(true);
	const listHeight = useRef<number>(0);
	const levelIDs = useRef<string[]>([]);
	const positionCache:TObject = useRef({});
	const comboRef = useRef<HTMLButtonElement>(null);
	const listContainerRef = useRef<HTMLUListElement>(null);
	const selectid = useRef<string | null>(null);
	const [focused, setFocused] = useState(-1);

	const setSelectid = () => {
		const now = Math.round(new Date(new Date()).getTime());
		selectid.current = `${id ? `${id}-` : ''}${Math.random()}-${now}`;
	}
	const comboMouseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		if (disabled) return;
		setComboOpen(!comboOpen);
		if (comboOpen && onMenuClose) {
			onMenuClose();
		} else {
			setShowList(false);
		}
		comboRef?.current?.focus();
	}
	const comboKeyClick = (e:KeyboardEvent<HTMLElement>) => {
		if (disabled) return;
		if (e.key === 'Tab' || e.keyCode === 9) {
			e.preventDefault();
			return;
		}
		if (e.key === 'Escape' || e.keyCode === 27) {
			e.preventDefault();
			comboRef?.current?.focus();
			setComboOpen(false);
			setContextState({
				hasNested: hasNested ?? false,
				hasMultiLevel: hasMultiLevel ?? false,
				hasAccordian: hasAccordian ?? false,
				prevLevel: [],
				setContextState
			});
			setShowList(false);
			if (onMenuClose) onMenuClose();
			return;
		}
		if (comboOpen && (e.key === 'ArrowDown' || e.keyCode === 40) && listContainerRef.current) {
			e.preventDefault();
			setFocused(0);
			(listContainerRef.current.querySelectorAll('li[role=option]')[0] as HTMLElement).focus();
		} else {
			e.preventDefault();
			setComboOpen(true);
			setTimeout(() => {
				setShowList(true);
			}, 50);
		}
	}

	const listItemMouseClick = (option:any, selected:boolean, groupName:string = '') => {
		let finalVal:any;
		const groups = contextState?.hasNested ? contextState?.prevLevel?.map((v) => v.name) ?? [] : [groupName];
		if (!multiple) {
			onChange(option, groups);
			setComboOpen(false);
			setContextState({
				hasNested: hasNested ?? false,
				prevLevel: [],
				setContextState
			});
			setShowList(false);
			if (onMenuClose) onMenuClose();
			finalVal = option;
		} else {
			let prevVal = [...value];
			if (selected) {
				prevVal = prevVal.filter((val) => option !== val)
			} else {
				prevVal.push(option);
			}
			onChange(prevVal);
			finalVal = prevVal;
		}
		if (validations) {
			const valid = validations.safeParse(finalVal);
			if (!valid.success) {
				const issues = [];
				for (let i=0, l=valid.error.issues.length; i<l; ++i) {
					issues.push(valid.error.issues[i].message);
				}
				const error = {
					field: name,
					issues
				};
				if (onValidationError) {
					onValidationError(error, false);
				}
			} else if (onValidationSuccess) {
				onValidationSuccess({ field: name }, true);
			}
		}
	}
	const listItemKeyClick = (e:KeyboardEvent<HTMLElement>, i:number, option:any, selected: boolean, groupName:string = '') => {
		let optsLen = 0
		if (listContainerRef.current) {
			optsLen = listContainerRef.current.querySelectorAll('li[role=option]').length;
		}
		if (e.key === 'Escape' || e.keyCode === 27) {
			comboRef?.current?.focus();
			setComboOpen(false);
			setContextState({
				hasNested: hasNested ?? false,
				prevLevel: [],
				setContextState
			});
			setShowList(false);
			if (onMenuClose) onMenuClose();
		}
		if (comboOpen && (e.key === 'ArrowDown' || e.keyCode === 40) && listContainerRef.current) {
			e.preventDefault();
			if (focused === optsLen - 1) return;
			(listContainerRef.current.querySelectorAll('li[role=option]')[focused + 1] as HTMLElement).focus();
			setFocused(focused + 1);
		}
		if (comboOpen && (e.key === 'ArrowUp' || e.keyCode === 38)) {
			e.preventDefault();
			if (i === 0 && comboRef !== undefined) {
				comboRef?.current?.focus();
			} else if (listContainerRef.current) {
				(listContainerRef.current.querySelectorAll('li[role=option]')[focused - 1] as HTMLElement).focus();
				setFocused(focused - 1);
			}
		}
		if (e.key === 'Enter' || e.keyCode === 13 || e.keyCode === 32) {
			let finalVal:any;
			const groups = contextState?.hasNested ? contextState?.prevLevel?.map((v) => v.name) ?? [] : [groupName];
			if (!multiple) {
				onChange(option, groups);
				comboRef?.current?.focus();
				setComboOpen(false);
				setContextState({
					hasNested: hasNested ?? false,
					prevLevel: [],
					setContextState
				});
				setShowList(false);
				if (onMenuClose) onMenuClose();
				finalVal = option;
			} else {
				let prevVal = [...value];
				if (selected) {
					prevVal = prevVal.filter((val) => option !== val)
				} else {
					prevVal.push(option);
				}
				onChange(prevVal);
				finalVal = prevVal;
			}
			if (validations) {
				const valid = validations.safeParse(finalVal);
				if (!valid.success) {
					const issues = [];
					for (let i=0, l=valid.error.issues.length; i<l; ++i) {
						issues.push(valid.error.issues[i].message);
					}
					const error = {
						field: name,
						issues
					};
					if (onValidationError) {
						onValidationError(error, false);
					}
				} else if (onValidationSuccess) {
					onValidationSuccess({ field: name }, true);
				}
			}
		}
	}

	const setButtonStyles = () => {
		const style: { [key: string]: string } = {}
		if (width) style['width'] = width;
		return style;
	}

	const setStyle = () => {
		if (comboRef.current && listContainerRef.current) {
			const { offsetHeight, offsetWidth } = comboRef.current;
			const { left, top } = comboRef.current.getBoundingClientRect();
			const { offsetHeight: oHeight } = listContainerRef.current;
			if (!listHeight.current || listHeight.current === 0) listHeight.current = oHeight;
			const styles:TObject = {};
			styles.left = 0;
			styles.top = 0;
			styles.width = `${offsetWidth}px`;
			styles.minWidth = 'max-content';
			styles.willChange = 'transform';
			if (isAboveFold) {
				styles.transform = `translate(${left}px, ${offsetHeight + top}px)`;
				const newTop = offsetHeight + top;
				const offset = listHeightOffset ?? 0;
				if ((newTop + listHeight.current) > (window.innerHeight)) {
					styles.height = `${Math.floor(window.innerHeight - (top + offsetHeight) - 10 - offset)}px`;
				}
				styles.maxHeight = `${Math.floor(window.innerHeight - (top + offsetHeight) - 10 - offset)}px`;
			} else {
				let newListHeight = oHeight;
				if ((listHeight.current ?? 0) > (top - 10)) {
					newListHeight = top - 10 - (listHeightOffset ?? 0);
					styles.height = `${newListHeight}px`;
					styles.maxHeight = `${newListHeight}px`;
				}
				styles.transform = `translate(${left}px, ${top - newListHeight}px)`;
			}
			return styles;
		}
		return {}
	}

	const childArray = Children.toArray(children);
	const options = Children.map(childArray, (child, index) => {
		const newID = uuid();
		levelIDs.current.push(newID);
		return cloneElement(child as ReactElement, {
			index,
			selected: value,
			group: group,
			multiple,
			listItemMouseClick,
			listItemKeyClick,
			selectid: selectid.current,
			currentID: levelIDs.current[index],
			levelIDs: levelIDs.current,
			parent: ''
		});
	});

	const getSelected = () => {
		let selected: string | string[];
		if (Array.isArray(value)) {
			selected = [];
		} else {
			selected = '';
		}
		Children.forEach(children, (opt) => {
			if (!React.isValidElement(opt)) return;
			if (opt.props.label) {
				Children.forEach(opt.props.children, (gOpt) => {
					if (!React.isValidElement(opt)) return;
					const { props } = gOpt;
					if (Array.isArray(value) && Array.isArray(selected)) {
						if (value.indexOf(props.value) >= 0) selected.push(props.children);
					} else if (props.value === value) {
						selected = props.children;
					}
				});
			} else {
				const { props } = opt;
				if (Array.isArray(value) && Array.isArray(selected)) {
					if (value.indexOf(props.value) >= 0) selected.push(props.children);
				} else if (props.value === value) {
					selected = props.children;
				}
			}
		});
		if (value !== '' && value !== null && value !== undefined) {
			if (Array.isArray(selected)) {
				return selected.join(', ')
			} else {
				return showGroupWithVal ? `${group ? group : ''}${groupValSeparator}${selected}` : selected;
			}
		} else {
			return placeholder;
		}
	}

	const blurred = (e:FocusEvent<HTMLButtonElement>) => {
		if (onBlur) onBlur(e);
		if (!showList && validations) {
			const valid = validations.safeParse(value);
			if (!valid.success) {
				const issues = [];
				for (let i=0, l=valid.error.issues.length; i<l; ++i) {
					issues.push(valid.error.issues[i].message);
				}
				const error = {
					field: name,
					issues
				};
				if (onValidationError) {
					onValidationError(error, false);
				}
			} else if (onValidationSuccess) {
				onValidationSuccess({ field: name }, true);
			}
		}
	}

	const closeMenu = () => {
		setComboOpen(false);
		setContextState({
			hasNested: hasNested ?? false,
			prevLevel: [],
			setContextState
		});
	}

	useEffect(() => {
		if (comboOpen && comboRef.current) {
			const halfWin = window.innerHeight / 2;
			const comboPos = comboRef.current.getBoundingClientRect().y + comboRef.current.offsetHeight;
			if (comboPos > halfWin && listContainerRef.current && listContainerRef.current.offsetHeight > (window.innerHeight - comboPos)) {
				setIsAboveFold(false);
			} else {
				setIsAboveFold(true);
			}
			positionCache.current = {};
			setTimeout(() => {
				setShowList(true)
			}, 100);
		}
	}, [comboOpen]);

	useEffect(() => {
		setSelectid();
	}, []);

	return (
		<SelectContext.Provider value={{
			hasNested,
			hasMultiLevel,
			hasAccordian,
			prevLevel: [],
			...contextState,
			setContextState
		}}>
			<div className={styles.parentContainer}>
				{label && <label className={styles.label} htmlFor='select-input'>{label}{required ? <span className={styles.required}> *</span> : <></>}</label>}
				<button
					role={'combobox'}
					tabIndex={tabIndex}
					aria-controls='select-list'
					aria-expanded={comboOpen}
					onClick={comboMouseClick}
					onKeyUp={comboKeyClick}
					className={`${styles.container} ${displayThin ? styles.displayThin : ''} ${disabled ? styles.disabled : ''} ${hasError ? styles.error : ''} ${borderless ? styles.borderless : ''}`}
					style={setButtonStyles()}
					ref={comboRef}
					aria-label={name || 'Select toggle menu button'}
					id={id}
					data-testid={dataTestId}
					onBlur={blurred}
				>
					{Icon && iconPos === SELECT_ICON_POS.LEFT && <Icon className={disabled ? styles.iconLeftDisabled : styles.iconLeft} />}
					<span className={`${styles.value} ${borderless ? styles.underlined : ''} ${styleValueAs === 'h1' ? styles.displayAsHeader : ''} ${getSelected() === placeholder ? styles.placeholder : ''}`}>
						{getSelected()}
					</span>
					<span className={`${styles.arrow} ${comboOpen ? styles.arrowDown : ''} ${disabled ? styles.arrowDisable : ''}`}>
						<ArrowDownIcon style={{ cursor: disabled ? 'default':'pointer' }} />
					</span>
					{Icon && iconPos === SELECT_ICON_POS.RIGHT && <Icon className={disabled ? styles.iconRightDisabled : styles.iconRight} />}
				</button>
				{hasError && <div className={styles.errorMsgContainer}>
					<Spacer size={5} />
					<Caption color={'var(--txt-error)'}>
						{errorMessage}
					</Caption>
				</div>}
				{keepErrorSpace && !hasError && <div className={styles.errorMsgContainer} />}
				{comboOpen && createPortal(<div className={styles.listContainer} onClick={closeMenu}>
					<ul ref={listContainerRef} className={`${styles.list} ${showList ? styles.showList : ''}`} id={'select-list'} role={'listbox'} style={setStyle()}>
						{options}
					</ul>
				</div>, document.body)}
			</div>
		</SelectContext.Provider>
	);
}

SelectContainer.Option = Option;
SelectContainer.OptGroup = OptGroup;

export const Select = SelectContainer;
